var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({
        name:'stocks-designations-create'
      })}}},[_c('i',{staticClass:"bi bi-plus fs-5"}),_c('span',[_vm._v(" Créer une désignation de stock ")])])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise fs-5"}),_c('span',[_vm._v(" Actualiser ")])])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.print()}}},[_c('i',{staticClass:"bi bi-printer fs-5"}),_c('span',[_vm._v(" Imprimer ")])])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.sendByEmail()}}},[_c('i',{staticClass:"bi bi-envelope fs-5"}),_c('span',[_vm._v(" Envoyer ")])])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.stocks),function(stock,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',[(stock)?_c('div',[_vm._v(" "+_vm._s(stock.reference)+" ")]):_vm._e()]),_c('td',[(stock)?_c('div',[_vm._v(" "+_vm._s(stock.fullName)+" ")]):_vm._e()]),_c('td',[(stock.cold_room)?_c('span',[_vm._v(" "+_vm._s(stock.cold_room.number)+" ")]):_vm._e()]),(_vm.$userRole == 'Admin')?_c('td',{staticClass:"p-0 m-0"},[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                  name: 'stocks-designations-edit',
                  params: { reference: stock.reference },
                })}}},[_c('i',{staticClass:"bi bi-pencil-square fs-5"})]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                  name: 'stocks-designations-delete',
                  params: { reference: stock.reference },
                })}}},[_c('i',{staticClass:"bi bi-file-x-fill fs-5 text-danger"})])])]):_vm._e()])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Désignation")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nº de Chambre")]),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }